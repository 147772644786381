import { PlaceholderImage } from '@wh/common/chapter/components/PlaceholderImage'
import { adTypeIdMap } from '@wh/common/chapter/types/AdType'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { MultiLineTextTruncation } from '@wh/common/chapter/components/MultiLineTextTruncation/MultiLineTextTruncation'
import { verticals } from '@wh/common/chapter/types/verticals'
import React, { FunctionComponent } from 'react'
import { css } from 'styled-components'
import { ResponsiveImage } from '@wh-components/core/ResponsiveImage/ResponsiveImage'
import { ITEM_HEIGHT_ESTATE_AUTO, ITEM_HEIGHT_JOBS } from '@wh/common/chapter/components/HorizontalSlider/HorizontalSlider'

interface JobAdvertSummaryProps {
    id: number
    footerText: string | undefined
    teaserAttribute: string | undefined
    title: string
    imageUrl: string | undefined
    href: string | undefined
    testIdPrefix: string
    onClickTaggingEvent?: () => void | Promise<void>
    isActive?: boolean
    color?: string
    inactiveColor?: string
    jobsVariant?: 'normal' | 'high'
}

export const JobAdvertSummaryComponent: FunctionComponent<JobAdvertSummaryProps> = ({
    id,
    footerText,
    teaserAttribute,
    title,
    imageUrl,
    href,
    onClickTaggingEvent,
    testIdPrefix,
    color = 'palette.verydarkgrey',
    inactiveColor = 'palette.grey',
    jobsVariant = 'normal',
    isActive = true,
}) => {
    return (
        <Box as="article">
            <ClientRoutingAnchorLink
                type="anchor"
                testId={`${testIdPrefix}-${id}`}
                aria-label={`Zur Anzeige ${title}`}
                href={isActive ? href : undefined}
                onClick={onClickTaggingEvent}
                color={color}
                underline="none"
                css={css`
                    outline: 0;
                `}
            >
                <Box
                    as="section"
                    display="flex"
                    height={jobsVariant === 'normal' ? ITEM_HEIGHT_JOBS : ITEM_HEIGHT_ESTATE_AUTO}
                    justifyContent="center"
                    alignItems="center"
                    position="relative"
                    marginBottom="s"
                    border="1px solid"
                    borderColor="palette.owl"
                    padding="xs"
                    // eslint-disable-next-line no-restricted-syntax
                    backgroundColor="#ffffff"
                    opacity={!isActive ? 0.3 : undefined}
                >
                    {imageUrl ? (
                        <ResponsiveImage
                            alt={title}
                            src={imageUrl}
                            testId={`${testIdPrefix}-image-${id}`}
                            maxHeight={72}
                            maxWidth="100%"
                            loading="lazy"
                        />
                    ) : (
                        <PlaceholderImage adType={adTypeIdMap.JOB_JOB} vertical={verticals.JOB} />
                    )}
                </Box>
                <Box as="section" display="flex" flexDirection="column" color={!isActive ? inactiveColor : undefined}>
                    <Box testId={`${testIdPrefix}-description-${id}`}>
                        <MultiLineTextTruncation lineCount={2} lineHeight={1.2} bgColor="semantic.surface">
                            {title}
                        </MultiLineTextTruncation>
                    </Box>
                    <Text fontSize="s" fontWeight="bold" truncate={true} as="p">
                        {teaserAttribute}
                    </Text>
                    {footerText && (
                        <Text fontSize="s" truncate={true} as="p">
                            {footerText}
                        </Text>
                    )}
                </Box>
            </ClientRoutingAnchorLink>
        </Box>
    )
}
